/* ----------------------------------------------------------------------------------------------------- */
/*  @ Main Tailwind file for injecting utilities.
/* ----------------------------------------------------------------------------------------------------- */
//@tailwind base;
//@tailwind components;
@tailwind utilities;

// https://tailwindcss.com/docs/reusing-styles
// Tailwind doesn't really recommend using @apply everywhere...
// But it is an option, I think especially for reuseable things such as page headers
