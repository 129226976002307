/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

@font-face {
  font-family: 'Open Sans', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wdth,wght@0,75..100,300..800;1,75..100,300..800&display=swap')
    format('truetype');
}
@font-face {
  font-family: 'Open Sans', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wdth,wght@0,75..100,300..800;1,75..100,300..800&display=swap')
    format('truetype');
}

@font-face {
  font-family: 'Open Sans', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wdth,wght@0,75..100,300..800;1,75..100,300..800&display=swap')
    format('truetype');
}

@font-face {
  font-family: 'Open Sans', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wdth,wght@0,75..100,300..800;1,75..100,300..800&display=swap')
    format('truetype');
}

.sleek-dialog-container .mat-mdc-dialog-container {
  padding: 0px;
  min-width: 350px;
}

/* Account for padding on the mat-card-content */
mat-dialog-container[role='dialog'].mat-mdc-dialog-container:has(form-card) {
  padding: 0px !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

mat-dialog-container.mat-mdc-dialog-container:has(:not(form-card)) .mat-mdc-dialog-content {
  padding: 0px !important;
  overflow: visible !important;
}

mat-dialog-container[role='dialog'].mat-mdc-dialog-container:has(form-card)::-webkit-scrollbar {
  display: none;
}

.mat-mdc-paginator-icon {
  color: var(--fuse-primary);
}

hr.separator {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mat-calendar-controls .mat-calendar-period-button.mat-mdc-button {
  margin: 0px !important;
}

.mat-mdc-tooltip .mdc-tooltip__surface {
  @apply text-xs #{!important};
}
.mat-mdc-slide-toggle {
  > .mat-internal-form-field.mdc-form-field {
    @apply space-x-1 #{!important};
  }

  .mdc-switch .mdc-switch__handle-track {
    .mdc-switch__handle {
      &::after,
      &::before {
        scale: 0.75 !important;
      }

      .mdc-elevation-overlay {
        height: 20px !important;
        width: 20px !important;
        position: relative !important;
        background-color: white !important;
      }
      .mdc-switch__icons {
        background-color: white !important;
        scale: 0.75 !important;
        border-radius: 100% !important;
      }
    }
  }
}

.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked.mat-primary .mdc-switch .mdc-switch__track::after {
  background-color: var(--fuse-primary) !important;
}
.mat-mdc-slide-toggle.mat-primary .mdc-switch__track::before {
  background-color: theme('colors.tilled-neutral.300') !important;
}
.mat-mdc-slide-toggle.mat-primary .mdc-form-field .mdc-switch {
  width: 40px !important;
  /* Possibly add back if all switches have text to the right */
  /* margin-right: 8px !important; */
}
.mdc-switch__track {
  height: 20px !important;
  border-radius: 100px !important;
}

.mdc-switch__shadow {
  scale: 0.8 !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: white !important;
}
.mat-mdc-mini-fab {
  min-height: 20px !important;
  min-width: 20px !important;
}

.ngx-mat-color-canvas .color-canvas-row .zone-strip {
  margin-left: 214px !important;
  margin-top: -201px !important;
}

.tilled-card-styling {
  padding-left: 24px !important;
  padding-right: 24px !important;
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.mat-calendar-previous-button {
  color: var(--fuse-primary) !important;
}

.mat-calendar-next-button {
  color: var(--fuse-primary) !important;
}
.mat-mdc-menu-panel.action-list-menu-arrow-top {
  overflow: visible !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

input,
textarea,
.mat-mdc-input-element {
  &::placeholder {
    color: rgba(var(--fuse-text-hint-rgb), var(--tw-text-opacity)) !important;
  }

  &::-moz-placeholder {
    color: rgba(var(--fuse-text-hint-rgb), var(--tw-text-opacity)) !important;
  }

  &::-webkit-input-placeholder {
    color: rgba(var(--fuse-text-hint-rgb), var(--tw-text-opacity)) !important;
  }

  &:-ms-input-placeholder {
    color: rgba(var(--fuse-text-hint-rgb), var(--tw-text-opacity)) !important;
  }
}

#field-hint {
  @apply font-normal text-tilled-secondary #{!important};
}
mat-tab-group.mat-mdc-tab-group mat-tab-header.mat-mdc-tab-header {
  @apply block #{!important};
  .mat-mdc-tab-labels {
    @apply flex space-x-2 #{!important};
  }
  .mat-mdc-tab-label-container {
    @apply z-10 flex-grow-0 overflow-hidden shadow-none #{!important};
    .mat-mdc-tab {
      @apply h-10 min-w-0 flex-grow-0 rounded-md px-5 py-0 text-tilled-secondary #{!important};
      .mdc-tab__ripple::before {
        @apply rounded-t-md bg-tilled-secondary #{!important};
      }
      .mdc-tab__content {
        @apply -mx-3 #{!important};
      }
      .mdc-tab__text-label {
        @apply pb-1 #{!important};
      }
      &.mdc-tab--active {
        @apply rounded-t-md #{!important};
        &.mdc-tab-indicator--active .mdc-tab__text-label {
          @apply text-tilled-primary #{!important};
        }
        .mdc-tab-indicator__content--underline {
          // @apply hidden #{!important};
          @apply border-tilled-primary #{!important};
        }
        .mdc-tab__text-label {
          @apply text-tilled-secondary #{!important};
        }
        .mat-ripple {
          @apply rounded-t-md #{!important};
        }
      }
    }
  }
}

.mat-mdc-form-field.mat-form-field-appearance-fill
  .mat-mdc-form-field-bottom-align.mat-mdc-form-field-subscript-wrapper:not(:has(mat-error, mat-hint)) {
  display: none !important;
}

.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper:not(:has(textarea)) {
  .mat-mdc-form-field-flex,
  .mat-mdc-form-field-infix {
    min-height: 48px !important;
    height: 48px !important;
  }
}

.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper:has(textarea) {
  .mat-mdc-form-field-flex,
  .mat-mdc-form-field-infix {
    max-height: 90px !important;
  }
}
